import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import "../styles/home.scss"

const HomePage = ({ data }) =>{
  var x=0
  console.log(data)
  return(
    <div id="homepage">
      <Helmet title="ShortOrme Productions" />
      <Header />
      {data.allFile.edges.map(edge => {
        x=x+1
        console.log(x)
        const {childImageSharp} = edge.node
        return( <Img className={`image showcase-${x}`} fluid={childImageSharp.fluid} alt="Image Alt" /> )        
      })}
    </div>    
  )
}

export default HomePage

export const query = graphql`
  query {
    allFile(filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "homepage"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`